import { h, Component } from 'preact';
import classy from 'classnames';
import style from './style';

export default class Obrazek extends Component {

  state = {
    src: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
    alt: 'Alternated text',
    srcset: '',
    dataSrc: false,
    loaded: false,
    class: ''
  }

  inview(entries, observer){
    entries.forEach(entry => {
      if(entry.intersectionRatio){
        entry.target.addEventListener('load', this.loading.bind(this));
        entry.target.src = entry.target.getAttribute("data-src");
        observer.unobserve(entry.target);
      }
    })
  }

  loading(event){
    if(event.target.complete) this.setState({
      loaded: true
    });
  }

  componentDidMount(){
    this.setState({
      dataSrc: this.props.src,
      alt: this.props.alt,
      srcset: this.props.srcset,
      loaded: false,
      class: this.props.class
    });

    const observer = new IntersectionObserver(this.inview.bind(this));

    observer.observe(this.element);
  }

	render() {
		return (
			<div className={style.image_container}>
				<div class={style[classy({hidden: this.state.loaded})]}>
          <img
            src="assets/loader.png"
            alt={this.state.alt}
            class={this.state.class} />
        </div>
        <img
          src={this.state.src}
          srcset={this.state.srcset}
          data-src={this.state.dataSrc}
          alt={this.state.alt}
          ref={element => this.element = element}
          class={this.state.class} />
			</div>
		);
	}
}