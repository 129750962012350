import { h } from 'preact';
// import style from './style';
import Helmet from 'preact-helmet';
import RightPanel from '../../components/right';
// import { Obrazek, div } from 'preact-lazy-img';
import Obrazek from '../../components/Obrazek/index.js';

const Home = () => (
	<div>
		<Helmet title="Technologie balení do sáčků a lahviček" />
		<div class="main">
			<div class="first_container news">
				<ul class="news_body">
					<li class="news_item">
						<h3>
							<a href="/technologie-vyroba/">Balení a technologie výroby</a>
						</h3>
						<a href="/technologie-vyroba/">
							<img src="../assets/technologie.jpg" alt="Technologie a výroba" />
						</a>
						<p class="perex">
						Neustále investujeme do vybavení potřebného k dosažení co nejlepších možných výsledků.
						</p>
					</li>
					<li class="news_item">
						<h3>
							<a href="/about/#kvalita/">Kvalita v našich rukách</a>
						</h3>
						<a href="/about/#kvalita">
							<img src="../assets/innovazione.jpg" alt="Technologie a výroba" />
						</a>
						<p class="perex">
						Kvalita rozhoduje o úspěchu. Kvalitní stroje jsou zárukou vaši dlouhodobé prosperity.
						</p>
					</li>
					<li class="news_item">
						<h3>
							<a href="/about/#zakaznici">Zákazníci v našich myslích</a>
						</h3>
						<a href="/about/#zakaznici">
							<img src="../assets/zakaznik.jpeg" alt="Technologie a výroba" />
						</a>
						<p class="perex">
						Oboustranná důvěra je nezbytným předpokladem úspěšného obchodního partnerství.
						</p>
					</li>
					<li class="clear">&nbsp;</li>
				</ul>
			</div>
			<div class="container">
				<div class="story">
					<h2>Co vám můžeme nabídnout?</h2>
					<p>Jsme společnost, která se zabývá průmyslovou a reklamním výrobou, resp. průmyslovým balením a plnením
					zboží (hmot) do sáčků nebo lahviček.
					</p>
					<ul>
						<li>převezmeme veškerou starost která se týká zabalení a naplnění produktu</li>
						<li>postaráme se o kompletní dodávku včetně míchání produktů, nebo zajištění receptůry</li>
						<li>nabízíme velký sortiment obalového materiálu a širokou škálu rozměrů, které se stále rozšiřují</li>
						<li>nabízíme profesionální potisk obalů na flexografických strojích,</li>
						<li>nabízíme komplexní logistické služeby, variabilitu přepravních řešení, konsignační sklady, nebo
					balení na míru</li>
						<li>samozřejmostí je přísná hygiena, vysoká kvalita a jakost výrobků,
						</li>
					</ul>
					<div>
						<Obrazek src="../assets/packing_lead.jpg" class="image" alt="Technologie balení - obrázek" />
					</div>
					<h2>
						<a href="/technologie-vyroba/baleni-pod-ochrannou-atmosferou/">Balení pod ochrannou atmosférou</a>
					</h2>
					<p>Použití technických plynů v oblasti potravinářství je jeden z hlavních pilířů zachování kvality a
				přirozených vlastností potravin.</p>
					<p>Na první pohled se zdá, že neexistuje souvislost mezi technickými plyny a trvanlivostí potravin,
					ale jsou to právě tyto plyny a jejich směsi, které zaručují uchování chuti a barvy potravin,
					jejich aroma a zároveň zabraňují vzniku a rozvoji nežádoucích mikrobiálních zárodků.
					</p>
					<h2>
						<a class="extra" href="/s-napadem-realizovat-jej-a-uspet-je-pro-nas-inspiraci-a-vyzvou-k-dalsi-praci/">Uspět, je pro nás inspirací a výzvou!</a>
					</h2>
					<p>Bestpack patří mezi subjekty, které se již několik let významnou měrou podílejí na budování trhu
					v oboru balení a plnění na zakázku. Úspěchy, kterých jsme dosáhli jsou podloženy cílevědomostí,
				tvrdou prací, ale i potřebnou dávkou štěstí.</p>
					<a href="../assets/cert1.full.jpg" title="Slovak Environmental Packaging Rules">
						<div>
							<Obrazek src="../assets/cert1.jpg" class="image" alt="Slovak Environmental Packaging Rules" />
						</div>
					</a>
					<p>
						<i>EU, Czech &amp; Slovak Environmental Packaging Rules</i>
					</p>
					<p>Naše firemní kultura postavená na pevných morálních principech, spolu s loajalitou našich zaměstnanců
					z nás dělají spolehlivého a osvědčeného partnera. Důkazem toho je, že i v dnešním globalizovaném
					světě může vyrůstat firma, která se bez zahraničního kapitálu a cizího know-how dokáže prosadit.
					Poznání, že je možné přijít s nápadem, realizovat jej a uspět, je pro nás inspirací a výzvou
					k další práci. <a href="/s-napadem-realizovat-jej-a-uspet-je-pro-nas-inspiraci-a-vyzvou-k-dalsi-praci/" class="more">více »</a>
					</p>
					<p><hr /></p>

					<a href="../assets/publicita.pdf" title="Je spolufinancován Evropskou unií">
						<div>
							<Obrazek src="../assets/publicita.jpg" class="image" alt="Je spolufinancován Evropskou unií." />
						</div>
					</a>
				</div>
				<RightPanel />
				<div class="clear" />
			</div>
		</div>
	</div>
);
export default Home;